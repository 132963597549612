declare let window: any

import React, { Component } from 'react'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import _ from 'lodash'
import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Entity } from 'shared-libs/models/entity'
import {
  eventsToEntitiesContent,
  entitiesContentToRows,
  IExportColumn,
} from 'shared-libs/models/types/storyboard/yms-utils'

interface IExportSettings {
  columns?: IExportColumn[]
}

/**
 * @uiComponent
 */
interface IYardAuditExportButton extends IBaseProps {
  text?: string
  // yard audit entity id to print
  value?: string
  entity?: Entity
  style?: React.CSSProperties
  exportSettings: IExportSettings
}

interface IPrintEntityButtonState {
  isLoading?: boolean
}

function column(label, formula): IExportColumn {
  return {
    label,
    formula,
  }
}

export class YardAuditExportButton extends Component<
  IYardAuditExportButton,
  IPrintEntityButtonState
> {
  public static defaultProps: Partial<IYardAuditExportButton> = {
    text: 'Export Yard Audit',
    className: classNames(Classes.BUTTON, Classes.INTENT_PRIMARY, Classes.SMALL, 'center'),
    exportSettings: {
      columns: [
        column('Trailer #', 'core_yms_trailer.name'),
        column('Carrier', 'core_yms_trailer.carrier.displayName'),
        column('Status', 'core_yms_trailer.status'),
        column('Issue(s)', 'core_yms_trailer.issues'),
        column('Seal Status', 'core_yms_trailer.sealStatus'),

        column('Previous Location', '_prevContent.core_yms_trailer.spot.displayName'),
        column('Current Location', 'core_yms_trailer.spot.displayName'),

        column('Previous Fuel Level', '_prevContent.core_yms_trailer.reefer.currentFuel'),
        column('Current Fuel Level', 'core_yms_trailer.reefer.currentFuel'),

        column('Previous Current Temp', '_prevContent.core_yms_trailer.reefer.currentTemp.value'),
        column('Current Temp', 'core_yms_trailer.reefer.currentTemp.value'),

        column('Previous Set Temp', '_prevContent.core_yms_trailer.reefer.setTemp.value'),
        column('Current Set Temp', 'core_yms_trailer.reefer.setTemp.value'),

        column('Notes', 'core_yms_trailer.notes'),

        column('Audited By', 'entity.modifiedBy.displayName'),
      ],
    },
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
    }
  }

  public render() {
    const { text, className, style } = this.props
    const { isLoading } = this.state

    if (isLoading) {
      return (
        <div className="grid-block">
          <LoadingSpinner />
        </div>
      )
    }

    return (
      <>
        <a style={style} className={className} onClick={this.handleClick}>
          {text}
        </a>
      </>
    )
  }

  private handleClick = async () => {
    this.setState({ isLoading: true })
    const columns = this.props.exportSettings?.columns ?? []
    const entity = await this.getYardAuditEntity()
    const context = {
      settings: apis.getSettings(),
      executionEntity: entity,
    }

    // convert change-set/snapshot events in to a compact entity with previous and current
    const entitiesContent = eventsToEntitiesContent(entity)

    // base on the export config, extract the revelant column data from the list of entities
    const rows = await entitiesContentToRows(entitiesContent, columns, context)

    // export ot csv
    this.exportToCsv(rows)

    //  perform export
    this.setState({ isLoading: false })
  }

  private exportToCsv(rows: string[][]) {
    const csvData = rows.map((row) => `"${row.join('","')}"`).join('\n')

    // https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
    const isIE11 = !!window.MSInputMethodContext && !!window.document.documentMode
    if (isIE11) {
      // https://stackoverflow.com/questions/47881250/typeerror-object-doesnt-support-this-action-in-ie-11
      const blob = new Blob([csvData], { type: 'text/csv; charset=utf-8' })
      window.navigator.msSaveBlob(blob, 'yard_audit_export.csv')
    } else {
      const blob = new File([csvData], 'yard_audit_export.csv', {
        type: 'text/csv; charset=utf-8',
      })
      const URL = window.URL || window.webkitURL
      const downloadUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = downloadUrl
      a.download = 'yard_audit_export.csv'
      document.body.appendChild(a)
      a.click()
    }
  }

  private getYardAuditEntity = async () => {
    const { value, entity } = this.props
    return !_.isEmpty(value) ? await apis.getStore().fetchOrGetRecord(value) : entity
  }
}
