// tslint:disable:member-ordering

// import Promise from bluebird and usage of async is causing the following error
//   TS2529: Duplicate identifier 'Promise'. Compiler reserves name 'Promise' in top level scope of a module containing async functions.
// TODO: maybe need to upgrade "es2015" to a newer version, for now, alias bluebird promise to a different name to avoid conflict
import { Promise as BPromise } from 'bluebird'
import { applyPatch } from 'rfc6902'
import { compare as jsonDiff } from 'fast-json-patch';
import { AddOperation } from 'rfc6902/diff'
import { EventEmitter as FacebookEventEmitter } from 'fbemitter'
import _ from 'lodash'
import moment from 'moment'
import { v4 } from 'uuid'
import { AJVSchemaValidator } from '../components/entity/ajv-validator'
import { DefaultSetter } from '../components/entity/default-setter'
import { Normalizer } from '../components/entity/normalizer'
import { EntityValidator, JSONValidator, ValidationErrorsMap } from '../components/entity/validator'
import { Formatter } from '../helpers/formatter'
import { JSONSchemaResolver } from '../resolvers/json-schema-resolver'
import { IEventEmitter } from './event-emitter'
import { EntityProps, EntitySchemaProps } from './prop-constants'
import { EntityProxy } from './proxy'
import { SchemaIds, SchemaUris } from './schema'
import { RequestMethod, Store } from './store'
import { getTypeById } from './types'
import { convertServerError, jsonPointerToObjectPath, mergeUndefined } from './utils'
import { Timer } from '../helpers/timer'
import Ajv from 'ajv'
import { RemoteFile } from '../generated/server-types/entity/fileSet';
import { PlatformType } from './types/storyboard/storyboard-plan';
import { evaluateExpression } from '../helpers/evaluation';
import { CustomFormulas } from '../helpers/formulas';
import { jsonPatchToObject } from './types/storyboard/storyboard-utils';

BPromise.config({
  cancellation: true,
})

const TRANSLATIONS_PATH = "translations"

const entityProxyHandler = {
  get: function (prop) {
    if (this) return this[prop]
  },
  set: function (prop, value) {
    throw new Error(`Cannot update ${prop} directly. Use entity.set(...)`)
  },
}
export interface ISaveActionProps {
  saveProps?: any
  method?: RequestMethod
  validate?: boolean
  patchContent?: any
}

export type ISaveActionResult = ISaveActionProps
interface ISaveAction {
  name: string
  action: (entity: any, props?: ISaveActionProps) => any // allow callback to overwrite the props
}

type ActionMap = {
  [id: string]: ISaveAction
}

type Edge = {
  entityId: string
  displayName: string
}

type Subschema = {
  context: any
  schema: any
}

/**
 * Describes an Edge found from doing an edge lookup.
 */
export type EdgeDetails = {
  value: Edge
  subschema: Subschema
  valuePath: string[]
  schemaPath: string[]
}

export class Entity extends EntityProxy implements IEventEmitter {
  public static Status = {
    New: 'new',
    Pending: 'pending',
    Idle: 'idle',
    Busy: 'busy',
    Error: 'error',
    Deleted: 'deleted',
  }

  public eventEmitter: any = new FacebookEventEmitter()
  public schemaResolver: JSONSchemaResolver

  public onReload: () => void // direct callback on reload instead of relying on event

  private api: any
  private defaultSetter: DefaultSetter
  // TODO(Peter): should migrate toward state machine
  // https://github.com/emberjs/data/blob/v2.16.0/addon/-private/system/model/states.js
  private isNewAndDeleted: any
  private multipartFiles: any
  private options: any
  private pendingChildEntities: any[]
  private pendingParentEntities: any[]
  private pendingAssociatedEntities: any[] // piggy back entities to be written back on save
  private store: Store
  private types: any
  private normalizer: EntityValidator
  private validator: JSONValidator
  private refreshTimer?: Timer
  private refreshPromise: BPromise
  // entity ids that need to be existed first before this entity could be saved
  private dependencies: string[] = []
  // Use for serialization purpose.  All the children jobs of the workflow should have the same tag
  // as workflow so it could be serialized correctly
  private jobTag: string

  private preSaveActions = {
    stickyHooks: {} as ActionMap, // run everytime before save
    tempHooks: {} as ActionMap,   // run once before save, then it will be clear
  }

  // Some edges carry denormalized properties on them.
  // When an edge to a new entity is created, the inflation of the denormalized properties takes time.
  // We keep track of the inflation session ids to prevent things like saving while inflation is still occuring.
  private pendingInflationSessionIds: string[]

  constructor(data, api) {
    super(data, entityProxyHandler)
    if (!data) {
      throw new Error('data must be present')
    }
    if (!api) {
      throw new Error('api must be present')
    }
    this.api = api
    this.store = api.getStore()
    this.multipartFiles = {}
    this.pendingChildEntities = []
    this.pendingAssociatedEntities = []
    this.pendingParentEntities = []
    this.pendingInflationSessionIds = []
    this.types = {}
    this.schemaResolver = new JSONSchemaResolver(api)
    const entityResolver = this.resolveSubschema.bind(this)
    this.defaultSetter = new DefaultSetter(this.schemaResolver, entityResolver)

    this.onReload = null

    // initialize proxy
    this.setPrevContent(this.content)
    this.registerOwnProperties()
    this.initialize()

    this.normalizer = new EntityValidator(this.schemaResolver)
    this.validator = new AJVSchemaValidator(this.schemaResolver, this)
  }

  public addListener(eventType, callback) {
    return this.eventEmitter.addListener(eventType, callback)
  }

  public emit(eventType, ...args) {
    return this.eventEmitter.emit(eventType, ...args)
  }

  /****************************************************************************/
  // Model related API
  /****************************************************************************/

  public getStore() {
    return this.store
  }

  public getSettings() {
    return this.api?.getSettings()
  }

  public applyDefaults() {
    const schemas = this.schemas
    const content = this.content
    _.forEach(schemas, (schema) => this.defaultSetter.apply(schema, content))
  }

  public addMixin(schema) {
    const activeMixins = this.activeMixins
    const inactiveMixins = this.inactiveMixins
    // TODO(Peter): change schema.title -> schema.displayName once bug is fixed
    const newMixin = {
      entityId: schema.uniqueId,
      displayName: schema.title,
    }
    _.pullAllBy(activeMixins, [newMixin], 'entityId')
    _.pullAllBy(inactiveMixins, [newMixin], 'entityId')
    activeMixins.push(newMixin)

    // create new namespace if it does not already exists
    const namespace = schema.get('metadata.namespace')
    this.set(namespace, this.get(namespace, {}))
    this.registerSchemaProperties(schema)
  }

  public addPreSaveAction(action: ISaveAction, isSticky = false) {
    const actionType = isSticky
      ? this.preSaveActions.stickyHooks
      : this.preSaveActions.tempHooks
    actionType[action.name] = action
  }

  public clearPreSaveActions() {
    this.preSaveActions.tempHooks = {} as ActionMap
  }

  private initialize() {
    this.schemas.forEach((schema) => {
      if (schema) {
        const namespace = this.getNamespace(schema)
        const mixin = this[namespace] || {}
        // console.log(namespace, mixin, this)
        if (mixin.initialize) {
          mixin.initialize(this)
        }
      }
    })
    this.checkCreator()
  }

  private getNamespace(schema) {
    const METADATA_NAMESPACE_PATH = 'metadata.namespace'
    // We could still be bootstapping so schema may not be wrapped in entity yet.  See ApplicationBundleResolver
    return schema.get ? schema.get(METADATA_NAMESPACE_PATH) : _.get(schema, METADATA_NAMESPACE_PATH)
  }

  public beforeChange(path, oldValue, newValue, silentUpdate = false) {
    const schemas = this.schemas
    _.forEach(schemas, (schema) => {
      const trigger = this.types[schema.uniqueId]
      if (trigger && trigger.beforeChangeTrigger) {
        trigger.beforeChangeTrigger(this, path, oldValue, newValue, silentUpdate)
      }
    })
  }

  // NOTE: `clone` creates an entity with a new uniqueId, `cloneDeep` copies the entity exactly
  public clone() {
    const content = _.cloneDeep(this.content)
    content.uniqueId = v4()
    return new Entity(content, this.api)
  }

  public cloneDeep() {
    const content = _.cloneDeep(this.content)
    const prevContent = this.prevContent
    const cloneEntity = new Entity(content, this.api)
    cloneEntity.setPrevContent(prevContent)
    cloneEntity.multipartFiles = _.cloneDeep(this.multipartFiles)
    return cloneEntity
  }

  /**
   * Take in a json diff patch and apply it to the entity.content if there is no
   * error
   *
   * @param patch json diff patch
   * @returns an array of errors
   */
  public applyPatch(patch) {
    if (_.isEmpty(patch)) {
      return []
    }

    const mergedContent = _.cloneDeep(this.content)
    const results = applyPatch(mergedContent, patch)
    const errors = _.compact(results)
    if (_.isEmpty(errors)) {
      this.setContent(mergedContent)
    } else {
      console.error('Unable to apply patch', JSON.stringify(patch, null, 2), JSON.stringify(errors))
    }
    return errors
  }

  public mergeWith(otherEntity) {
    const otherContent = _.cloneDeep(otherEntity.content)
    const otherFiles = _.cloneDeep(otherEntity.multipartFiles)

    // console.log('[Entity.mergeWith]:otherMultipartFiles='
    //   + JSON.stringify(otherFiles, null, '\t')
    //   + '\n,currentFiles=' + JSON.stringify(this.multipartFiles, null, '\t')
    //   + '\n,currentEntity=' + JSON.stringify(this.content, null, '\t')
    //   + '\n,otherEntity=' + JSON.stringify(otherEntity.content, null, '\t'))

    const currModifiedDate = this.get('modifiedDate')
    const otherModifiedDate = otherEntity.get('modifiedDate')
    const isOtherNewer =
      currModifiedDate !== otherModifiedDate &&
      ((_.isNil(currModifiedDate) && !_.isNil(otherModifiedDate)) ||
        moment(this.get('modifiedDate')).isBefore(moment(otherEntity.modifiedDate)))

    // very basic merge (overwrite) based on the timestamp
    if (isOtherNewer) {
      // overwrite the gut of this entity
      this.setContent(otherContent)
      this.multipartFiles = otherFiles
    }

    // console.log('[Entity.mergeWith]:mergedFiles='
    //   + JSON.stringify(this.multipartFiles, null, '\t')
    //   + '\n,mergedEntity=' + JSON.stringify(this.content, null, '\t'))
  }

  /**
   * Attempt to merge the files between two entities.  If the jsonPatch is provided, it means
   * only the jsonPatch will be send out as a PATCH request so perform deep inspection of the patch
   * for any local file references
   *
   * @param override
   * @param jsonPatch
   * @returns
   */
  public mergeFiles(other, override = false, jsonPatch = {}, analyticLogger = null) {
    if (_.isNil(other)) {
      return
    }
    const nowDate = moment.utc().format()
    const currDate = this.get('modifiedDate', nowDate)
    const otherDate = other.get('modifiedDate', nowDate)
    const isNewer = moment(otherDate).isSameOrAfter(currDate)
    const hasLocalFiles = !_.isEmpty(jsonPatch) && !_.isEmpty(this.getLocalFileRefFromBlob(jsonPatch))

    // if detect VD-9651, missing file attachment due a skew 'modifiedDate', log it so we know the fix is working
    if (!isNewer && hasLocalFiles && analyticLogger != null) {
      analyticLogger("Missing_File_Attachment_Fix", {
        thisModifiedDate: this.get('modifiedDate'),
        otherModifiedDate: other.get('modifiedDate'),
        nowDate: nowDate,
        thisMultipartFiles: Object.keys(this.multipartFiles).length,
        otherMultipartFiles: Object.keys(other.multipartFiles).length,
        isNewer: isNewer,
        hasLocalFiles: hasLocalFiles
      })
    }

    if (isNewer || override || hasLocalFiles ) {
      this.multipartFiles = _.merge(this.multipartFiles, other.multipartFiles)
    }
  }

  public cloneForReactState() {
    // Need to re-set prevContent so entity will stay dirty
    const content = _.cloneDeep(this.content)
    const prevContent = this.prevContent
    const entity = new Entity(content, this.api)
    entity.setPrevContent(prevContent)
    return entity
  }

  public delete() {
    // if record is not saved yet, we don't need to make an api call
    if (this.isNew) {
      this.isNewAndDeleted = true
    } else {
      return this.store.deleteRecord(this)
    }
  }

  public invalidate() {
    return this.store.invalidateRecord(this)
  }

  public recompute() {
    return this.store.recomputeRecord(this)
  }

  public hasMixin(uniqueId) {
    return !!_.find(this.activeMixins, { entityId: uniqueId })
  }

  public hasType(typeIds: string[]) {
    const schemaUrisIds = this.getTypes()
    const matched = _.intersection(schemaUrisIds, typeIds)

    return !_.isEmpty(matched)
  }

  /**
   * Return all possible types in uri and uuid format from allOf/anyOf/oneOf/mixin for
   * entity or entity schema
   */
  public getTypes() {
    const anyOf = this.get(EntitySchemaProps.ANY_OF) || []
    const allOf = this.get(EntitySchemaProps.ALL_OF) || []
    const mixins = this.get(EntityProps.ACTIVE_MIXINS) || []

    const allTypes = _.compact([...anyOf, ...allOf, ...mixins])
    const allTypeIds = _.map(allTypes, (type) => _.get(type, '$ref') || _.get(type, 'entityId'))
    const normalizedIds = _.flatMap(allTypeIds, (id) => {
      const entity = this.store.getRecord(id)
      // get its uuid and uri
      return [_.get(entity, 'id'), entity.uniqueId]
    })
    return [...normalizedIds, this.get(EntityProps.ID), this.get(EntitySchemaProps.URI)]
  }

  public hasAnyMixin(mixins: string[]) {
    if (_.isEmpty(mixins)) {
      return true
    }
    const activeMixins = _.map(this.activeMixins, 'entityId')
    const matched = _.intersection(activeMixins, mixins)

    return !_.isEmpty(matched)
  }

  public reload() {
    const oldContent = _.cloneDeep(this.content)
    const uniqueId = this.uniqueId
    return this.store.findRecord(uniqueId).then((entity) => {
      if (!_.isEqual(oldContent, entity.content)) {
        // TODO(Peter): possible that the returned entity !== this see cacheRecord
        // console.log('[entity.reload.findRecord]:id=' + uniqueId + ',content=' + JSON.stringify(entity.content))
        this.setAllContents(entity.content)
        this.store.emit(Store.RECORD_CHANGED, this)
        this.emit(Store.RECORD_CHANGED, this)
        this.onReload && this.onReload()
      }
      return this
    })
  }

  public retry() {
    if (this.isNew) {
      return BPromise.resolve()
    }
    return this.store.retryRecord(this)
  }

  public rollback() {
    super.rollback()
    _.forEach(this.pendingChildEntities, (entity) => entity.rollback())
    _.forEach(this.pendingAssociatedEntities, (entity) => entity.rollback())

    // clear non-sticky props
    this.clearPreSaveActions()

    this.emit(Store.RECORD_RESET, this)
  }

  /**
   * @returns Whether {@link saveDraft} should be a no-op.
   */
  public get saveBlocked(): boolean | undefined {
    return _.get(this, '_metadata.saveBlocked')
  }

  /**
   * Makes {@link saveDraft} a no-op.
   */
  public set saveBlocked(value: boolean) {
    _.set(this, '_metadata.saveBlocked', value)
  }

  public save(props?, method: RequestMethod = RequestMethod.PUT, validate = true, patchContent = null) {
    return new BPromise<ValidationErrorsMap>(async (resolve, reject) => {
      validate = props?.validate ?? validate
      const shouldValidate = props?.validatePatchOnly ? (validate && !_.isEmpty(patchContent)) : validate
      const errors = shouldValidate && (await this.validate(true, null, patchContent, props))

      if (!_.isEmpty(errors)) {
        console.error('Validation error', errors)
        return reject({ errors })
      }
      return this.saveDraft(props, method, patchContent).then(resolve).catch(reject)
    })
  }

  public saveWithoutValidation(props?) {
    return this.saveDraft(props)
  }

  public saveChildren(props?) {
    const pendingEntities = this.pendingChildEntities
    this.pendingChildEntities = []
    const promises = _.map(pendingEntities, (entity) => entity.save(props))
    return BPromise.all(promises)
  }

  public saveAssociated(props?) {
    const pendingEntities = this.pendingAssociatedEntities
    if (_.isEmpty(pendingEntities)) {
      return BPromise.resolve()
    }
    this.pendingChildEntities = []
    const promises = _.map(pendingEntities, (entity) => entity.save(props))
    return BPromise.all(promises)
  }

  // save draft does not validate
  public async saveDraft(props?, method: RequestMethod = RequestMethod.PUT, patchContent = null): Promise<Entity> {
    // no-op if entity is configured to block saves
    if (this.saveBlocked) {
      return BPromise.resolve(this)
    }

    const saveTaskId = props?.saveTaskId

    // strip metadata
    Normalizer.stripMetadata(this.content)

    // strip out computed/transient namespace
    _.unset(this.content, '_computedName')
    _.unset(this.content, '_transient')

    const defaultProps: ISaveActionProps = {
      saveProps: props,
      method: method,
      patchContent: patchContent
    }

    // run pre-save action hooks and merge all the override values into the default ones
    const results = await this.runPreSaveActions(defaultProps)
    const overrideProps = _.reduce(results, _.assignIn)
    const selfSaveProps: any = _.assignIn(defaultProps, overrideProps)

    return this.saveParents(props)
      .then(() => this.store.saveRecord(this, { ...selfSaveProps.saveProps, saveTaskId }, selfSaveProps.method, selfSaveProps.patchContent))
      .then(() => this.saveChildren(props))
      .then(() => this.saveAssociated(props))
      .then(() => {
        this.clearPreSaveActions()
        this.clearSaveDependencies()
        this.clearJobTag()
        this.pendingAssociatedEntities = []
        // once the save request is send, sync the prevContent in order
        // to correctly calculate the next edit patch
        this.setPrevContent(this.content)
        this.api.logger?.info('Upload_Entity_Succeeded', {entityId: this.uniqueId})
        return this
      })
      .catch((error) => {
        // if we are on the web error will have a response JSON
        if (!error.responseJSON) {
          throw error
        }
        const convertedErrors = convertServerError(error)
        this.api.logger?.info('Upload_Entity_Failed', {entityId: this.uniqueId, errors: convertedErrors})
        throw convertedErrors
      })
  }

  public saveParents(props?) {
    const pendingEntities = this.pendingParentEntities
    this.pendingParentEntities = []
    const promises = _.map(pendingEntities, (entity) => entity.save(props))
    return BPromise.all(promises)
  }

  public savePrevContent() {
    return this.store.updateJSON(this, this.prevContent)
  }

  public undelete() {
    if (this.isNew) {
      this.isNewAndDeleted = false
    } else {
      return this.store.undeleteRecord(this)
    }
  }

  public waitUntil(condition, initialTimeout, secondaryTimeout = 4000) {
    let timer
    const checkIfCondition = (resolve, reject) => {
      if (condition(this)) return resolve(this)
      this.reload()
        .then(() => {
          if (condition(this)) return resolve(this)
          timer = setTimeout(() => checkIfCondition(resolve, reject), secondaryTimeout)
        })
        .catch(reject)
    }
    return new BPromise((resolve, reject, onCancel) => {
      timer = setTimeout(() => checkIfCondition(resolve, reject), initialTimeout)
      onCancel(() => clearTimeout(timer))
    })
  }

  public waitUntilIdle(initialTimeout = 2000, secondaryTimeout = 4000) {
    return this.waitUntil(
      (entity) => {
        const status = this.get('status.state')
        return status === Entity.Status.Idle || status === Entity.Status.Error
      },
      initialTimeout,
      secondaryTimeout
    )
  }

  public waitUntilDeleted(timeout = 2000) {
    return this.delete().then(() => {
      return this.waitUntil(() => {
        const status = this.get('status.state')
        return status === Entity.Status.Deleted
      }, timeout)
    })
  }

  /**
   *
   * @param useAjv AJV validator
   * @param customValidationFunction use custom or overwrite validation function instead
   * @param patchContent  patch content
   * @param validatePatchOnly best effort to validate on the patch only instead of the whole entity
   */
  public async validate(useAjv = true, customValidationFunction: Ajv.ValidateFunction = null, patchContent = null, options: any = {}) {
    const { validatePatchOnly = false } = options

    if (validatePatchOnly) {
      return _.isEmpty(patchContent) ? {} : this.validatePatch(patchContent)
    }

    this.applyDefaults()
    return customValidationFunction
      ? AJVSchemaValidator.validate(customValidationFunction, this.content)
      : this.defaultValidate(useAjv)
  }

  private getValidator(useAjv = true) {
    if (!useAjv) return new EntityValidator(this.schemaResolver)
    return this.validator
  }

  public addInflationSessionId(id) {
    this.pendingInflationSessionIds.push(id)
  }

  public removeInflationSessionId(id) {
    const removeIndex = this.pendingInflationSessionIds.indexOf(id)
    if (removeIndex !== -1) {
      this.pendingInflationSessionIds.splice(removeIndex, 1)
    }
  }

  public areInflationsPending(): boolean {
    return !_.isEmpty(this.pendingInflationSessionIds)
  }

  public clearDirt() {
    this.setPrevContent(this.content)
  }

  /**
   * Calculate the json patch diff between the current and the original value.
   * The 'content' is the value modified by the client UI
   * The 'original' is the actual value, unmodified, pristine value from the server
   */
  public jsonPatch(currContent = null, newContent = null, options: any = {}): any {
    /**
     * 'includedPaths': path to be included in the json patch calculation
     */
    const { includedPaths = [] } = options

    const prev = currContent || this.origContent
    const curr = newContent || this.content

    const normalizedPrev = _.isEmpty(includedPaths) ? prev : _.pick(prev, includedPaths)
    const normalizedCurr = _.isEmpty(includedPaths) ? curr : _.pick(curr, includedPaths)
    return this.enrichJsonPatch(jsonDiff(normalizedPrev, normalizedCurr), currContent)
  }

  /**
   * Enhance the JSON patch to include prevValue as an additional condition to ensure an element is removed
   * if both the index and prevValue match. In the "remove" or "replace" patch operations,
   * the removal is based on the index, which might be shifted due to other tasks performing operations on the same array.
   *
   * @param jsonPatch
   * @param options
   * @returns
   */
   public enrichJsonPatch(jsonPatch: any[], prevContent: any, options: any = {}) {
    const { transformOps = ['remove', 'replace'] } = options // path to be included json patch calculation only

    return _.map(jsonPatch, patch => {
      if (!_.includes(transformOps, patch.op)) {
        return patch
      }

      let enrichedPatch = patch
      const path = jsonPointerToObjectPath(patch.path)
      const prevValue = _.get(prevContent, path)
      switch(patch.op) {
        case 'remove':
        case 'replace':
          enrichedPatch = {
            ...patch,
            prevValue
          }
          break
        default:
          break
        }
      return enrichedPatch
    })
  }

  /**
   * Normalize json patch for server by transform 'remove'/'replace' operation
   * to 'add' operation to avoid conflict
   *
   * VD-5992: Since the server can transform incoming entities, a path being
   * removed or replaced might not exist, and cause a 400. By changing the
   * verb to 'add', we will replace an existing value if it exists, or add
   * a path that didn't, which the server may just remove again.
   * This can also happen when there are multiple users both editing the same
   * entity and there are operation conflicts.
   */
  public normalizeJsonPatchForServer(jsonPatch: any[], options: any = {} ) {
    const { transformOps = [] } = options // path to be included json patch calculation only

    /**
     *  remove the "noise", properties are not defined in the schema or denormalized properties, from the patch
     */
    const denoisePatch = _.filter(jsonPatch, (diff) => {
      // json diff path format '/core_storyboard_execution/events/-' so
      // need to normalize it to 'core_storyboard_execution.events'
      const path = _.get(diff, 'path', '').substring(1)
      const normalizedPath = path.replace(new RegExp('/', 'g'), '.').replace(/\.-$/, '')

      // valid path defined in schema
      return  !_.startsWith(path, 'status') && !_.isEmpty(this.resolveSubschemaByValuePath(normalizedPath))
    })

    return _.map(denoisePatch, diff => {
      // VD-5992: Since the server can transform incoming entities, a path being
      // removed or replaced might not exist, and cause a 400. By changing the
      // verb to 'add', we will replace an existing value if it exists, or add
      // a path that didn't, which the server may just remove again.
      // This can also happen when there are multiple users both editing the same
      // entity and there are operation conflicts.
      if (!_.includes(transformOps, diff.op)) {
        return diff
      }

      switch(diff.op) {
        case 'remove':
          diff = {
            op: 'add',
            path: diff.path,
            value: null
          } as AddOperation
          break
        case 'replace':
          diff = {
            op: 'add',
            path: diff.path,
            value: diff.value
          } as AddOperation
          break
        default:
          break
        }
      return diff
    })

  }

  /**
   * Ensure that creator is set for locally-created entities.
   * The creator will be overwritten when saved to the server, so should
   * always be safe.
   */
  public checkCreator(): void {
    if (_.isNil(this.get('createdBy'))) {
      const settings = this.getSettings()
      const creator = settings?.getUser()?.owner?.user
      if (!_.isNil(creator)) {
        this.commit('createdBy', {...creator})
      }
    }
  }

  /**
   * Gather all the default values from different paths inside 'values' and
   * set it to the entity content
   */
  public withDefaultValues(values = {}) {
    if (_.isEmpty(values)) {
      return this
    }
    const normalizedValues = this.normalizeDefaultValues(values)

    const entityProps = Object.keys(this.content)
    const defaultProps = Object.keys(normalizedValues)

    // only update the valid props

    // build default values to merge, don't overwrite in case of edit
    const value = {}
    const matchingProps = _.intersection(entityProps, defaultProps)
    matchingProps.forEach((propName) => {
      const propValue = _.get(normalizedValues, propName)
      _.set(value, propName, _.cloneDeep(propValue))
    })

    // get all the default values from nested mixinIds and set it
    const activeMixinIds = _.map(this.activeMixins, 'entityId')
    const updateMixinIds = _.intersection(activeMixinIds, defaultProps)

    for (const mixinId of updateMixinIds) {
      const mixinValue = _.get(normalizedValues, mixinId, {})
      _.merge(value, mixinValue)
    }

    mergeUndefined(this.content, _.cloneDeep(value))

    // resconstruct multipartFiles from blob value
    this.addMultipartFilesFromBlob(value)

    return this
  }

  public markAccessTime(timeMs: number): void {
    _.set(this, '_metadata.last_access_time', timeMs)
  }

  /****************************************************************************/
  // Model related actions
  /****************************************************************************/

  /**
   * Add text or file to a comment feed entity
   *
   * @param comment - optional comment text
   * @param attachmentInfo - platform-agnostic attachment data
   */
  public addComment(
    comment: string | undefined,
    locale = 'en-US',
    attachmentInfo?: {
      uniqueId: string
      body: any
      remoteFile?: RemoteFile
    },
    saveProps?: any
  ) {
    const commentSchema = this.store.getRecord(SchemaUris.COMMENT)
    const commentEntity = this.store.createRecord(commentSchema)
    if (comment) {
      commentEntity.set('comment.text', comment)
      commentEntity.set('comment.locale', locale)
    }
    if (attachmentInfo) {
      const { uniqueId, body, remoteFile } = attachmentInfo
      commentEntity.addMultipartFiles('comment.media.files', [{ file: body, uniqueId }])
      commentEntity.set('comment.media.files', [remoteFile])
    }
    commentEntity.set('comment.entity', {
      entityId: this.get('uniqueId'),
    })
    return this.saveChildRecord(commentEntity, saveProps)
  }

  public addShare(contact, inviteMessage?) {
    const shareSchema = this.store.getRecord(SchemaUris.SHARE)
    const shareEntity = this.store.createRecord(shareSchema)
    shareEntity.set('share.sharedTo', contact)
    shareEntity.set('share.message', inviteMessage)
    shareEntity.set('share.entity', {
      entityId: this.uniqueId,
    })
    return this.saveChildRecord(shareEntity)
  }

  public addParentRecord(entity) {
    const pendingEntity = this.getParentRecord(entity.uniqueId)
    if (!pendingEntity) {
      this.pendingParentEntities.push(entity)
    }
  }

  public getParentRecord(uniqueId) {
    return _.find(this.pendingParentEntities, { uniqueId })
  }

  public removeParentRecord(entity) {
    _.remove(this.pendingParentEntities, { uniqueId: entity.uniqueId })
  }

  public saveChildRecord(entity: Entity, props?) {
    if (this.isNew) {
      this.pendingChildEntities.push(entity)
      return BPromise.resolve(entity)
    } else {
      return entity.save(props)
    }
  }

  public addAssociated(entity) {
    this.pendingAssociatedEntities.push(entity)
  }

  public addSaveDependency(entityId: string) {
    this.dependencies.push(entityId)
  }

  public getJobTag() : string {
    return this.jobTag
  }

  public setJobTag(jobTag: string) {
    this.jobTag = jobTag

  }

  public clearSaveDependencies() {
    this.dependencies = []
  }

  public clearJobTag() {
    this.jobTag = ''
  }

  /****************************************************************************/
  // Schema Resolution
  /****************************************************************************/

  public resolveSubschema(subschema) {
    for (let i = this.schemas.length - 1; i >= 0; --i) {
      const context = this.schemas[i]
      const result = this.schemaResolver.resolveSubschema(context, subschema)
      if (result) {
        return result
      }
    }
    throw new Error(`[Renderer]: Cannot resolve schema=${subschema}`)
  }

  // given path, return the subschema (using one of the entity's schema)
  public resolveSubschemaByPath(path) {
    if (!_.isArray(path)) {
      path = path.split('.')
    }
    for (let i = this.schemas.length - 1; i >= 0; --i) {
      const context = this.schemas[i]
      const result = this.schemaResolver.resolveSubschemaByPath(context, path)
      if (result) {
        return result
      }
    }
    return null
  }

  public resolveSubschemaByValuePath(path) {
    path = JSONSchemaResolver.getSchemaPathFromValuePath(path)
    return this.resolveSubschemaByPath(path)
  }

  /**
   * Find edges recursively.
   */
  public getEdges(): EdgeDetails[] {
    const edges = []
    const find = (obj: any, valuePath = []): void => {
      if (!_.isNil(obj.entityId)) {
        const schemaPath = JSONSchemaResolver.getSchemaPathFromValuePath(valuePath)
        const subschema = this.resolveSubschemaByPath(schemaPath)
        const ref = _.get(subschema, ['schema', JSONSchemaResolver.REF_KEY])
        if (ref === SchemaUris.EDGE) {
          edges.push({
            value: obj,
            subschema,
            valuePath,
            schemaPath,
          })
        }
      }
      for (const key of Object.keys(obj)) {
        const value = obj[key]
        const path = [...valuePath, key]
        if (_.isObject(value)) {
          find(value, path)
        }
      }
    }
    find(this.content)
    return edges
  }

  public getFilteredAssociations(filter: (details: EdgeDetails, index: number, fullArray: EdgeDetails[]) => boolean): EdgeDetails[] {
    return this.getEdges().filter(filter)
  }

  public getStoryboardAssociations(): string[] {
    const filter = (details: EdgeDetails): boolean => {
      const value = details.valuePath
      return value.indexOf('core_storyboard_execution') != -1 && value.indexOf('associations') != -1 && details['value']['entityId'] !== null
    }
    return this.getFilteredAssociations(filter).map((association: EdgeDetails) => {
      return association['value']['entityId']
    })
  }

  /****************************************************************************/
  // File Upload
  /****************************************************************************/

  public addMultipartFiles(path, files) {
    if (_.isArray(path)) path = path.join('.')
    // console.log('[Entity.addMultipartFiles]:path=' + path + ',files=' + JSON.stringify(files) + ',multipartFiles=' + JSON.stringify(this.multipartFiles))
    const multipartFiles = this.multipartFiles[path]
    if (!multipartFiles) {
      this.multipartFiles[path] = files
    } else {
      multipartFiles.push(...files)
    }
  }

  public hasAttachments() {
    return !_.isEmpty(this.multipartFiles)
  }

  public removeMultipartFileByIds(id) {
    _.forEach(this.multipartFiles, (value, key) => {
      _.remove(value, (file: any) => file.uniqueId === id)
    })
  }

  public clearMultipartFiles(path) {
    if (_.isArray(path)) path = path.join('.')
    if (path) {
      delete this.multipartFiles[path]
    } else {
      this.multipartFiles = {}
    }
  }


  /**
   * Scrape a blob object for any file that still has local reference and append
   * it to the `multipartFiles`
   *
   * @param value: blob of data
   * @param path: an override path to set multipartFiles.  If null, it will auto determine
   *   the path from the data blob
   */
  public addMultipartFilesFromBlob(data: any, path = null) {
    const localFiles = this.getLocalFileRefFromBlob(data)

    _.forEach(localFiles, (value, key) => {
      this.addMultipartFiles(path ||  key, value)
    })
    return localFiles
  }

  private async defaultValidate(useAjv: boolean) {
    const results: ValidationErrorsMap = {}
    const validator = this.getValidator(useAjv)
    const schemas = this.schemas

    for (const schema of schemas) {
      // TODO(Dan): Refactor old validator's normalization logic out to a separate utility.

      // Ignore normalizer's validation errors - only using it for normalization
      // of the document.
      await this.normalizer.validate(schema, this.content)

      const errors = await validator.validate(schema.content, this.content)
      const trigger = this.types[schema?.uniqueId]
      if (trigger && trigger.validateTrigger) {
        trigger.validateTrigger(this, errors)
      }
      errors.forEach(entry => {
        results[entry.path.join('.')] = entry.errors
      })
    }
    return results
  }

  /**
   * Scrape the blob of data for any references to the local file or unprocessed file
   *
   */
  private getLocalFileRefFromBlob(blob: any) {
    const localMultipartFiles = {}

    // file object pattern
    const isLocalRef = (obj: any) =>
      _.isObject(obj)
      && _.has(obj, 'uri')
      && _.has(obj, 'uniqueId')
      && !_.startsWith(_.get(obj, 'uri', ''), 'http')

    const findLocalFiles = (obj: any, path: string[] = []) => {
      const isValid =  _.isPlainObject(obj) || _.isArray(obj)

      if (!isValid) {
        return
      }

      // if obj matches a file obj pattern, save it
      if (isLocalRef(obj)) {
        // form an image id to image content map
        const fileId = _.get(obj, 'uniqueId')
        if (!_.isEmpty(fileId)) {
          localMultipartFiles[fileId] = [obj]
        } else {
          console.error('Invalid image object', obj)
        }
        return
      }

      _.forEach(obj, (value, keyOrIndex) => {
        if (!_.isEmpty(value) && _.isObject(value)) {
          findLocalFiles(value, [...path, keyOrIndex])
        }
      })
    }
    // if blob is json patch, convert it object to feed into the function to find local reference
    const data = this.isJsonPatch(blob) ? jsonPatchToObject(blob) : blob
    findLocalFiles(data, [] )

    return localMultipartFiles
  }

  /**
   * Check if blob is a json patch
   * @param blob
   */
  private isJsonPatch(blob: any) {
    const patchOps = new Set([ 'replace', 'add', 'remove' ])
    return _.isArray(blob) && !_.find(blob, (element) => !patchOps.has(element?.op))
  }

  /**
   * Apply json patch to an object
   */
  public jsonPatchToObject = (blob: any[], valuePath = 'value') => {
    const patchOps = new Set([ 'replace', 'add', 'remove' ])
    return _.reduce(blob, (acc, patch) => {
      if (patchOps.has(patch?.op)) {
        const path = jsonPointerToObjectPath(patch?.path)
        const value = _.get(patch, valuePath)
        if (value != null) {
           _.set(acc, path, value)
        }
      }
      return acc
    }, {})
  }

  /**
   * Validate against the patch only by running against the relevant schema and
   * filter out errors that are not related to the patch. We could truncate the schema,
   * but that would require schema recompilation.  This is the best effort or hacky code
   * to run validation for storyboard execution on navigate forward
   * @param patchContent
   */
  private validatePatch = async (patchContent: any[], useAjv = true) => {
    const results: ValidationErrorsMap = {}
    const validator = this.getValidator(useAjv)

    // expand the patch
    const content = jsonPatchToObject(patchContent)
    const validPaths = _.map(patchContent, 'path') ?? []

    // find all the schemas to run against the diff
    const namespaces = _.keys(content)
    const schemas = _.filter(this.schemas, (schema) => namespaces.includes(_.get(schema, 'metadata.namespace')))

    for (const schema of schemas) {
      await this.normalizer.validate(schema, content)

      const errors = await validator.validate(schema.content, content)
      const trigger = this.types[schema?.uniqueId]
      if (trigger && trigger.validateTrigger) {
        trigger.validateTrigger(this, errors)
      }
      // filter out errors that not related the patch by checking against the patch content
      const validErrors = _.filter(errors, (error) => {
        const errorPath = '/' + error?.path?.join('/')
        return validPaths.some(path =>  errorPath.startsWith(path))
      })
      validErrors.forEach(entry => {
        results[entry.path.join('.')] = entry.errors
      })
    }
    return results
  }

  /****************************************************************************/
  // Computed properties
  /****************************************************************************/

  public get activeMixins(): Edge[] {
    return this.get('mixins.active', [])
  }

  public get inactiveMixins(): Edge[] {
    return this.get('mixins.inactive', [])
  }

  public get uniqueId(): string {
    return this.get('uniqueId')
  }

  public get displayName() {
    const displayNameFormat = this.getProperty('metadata.displayName')
    if (displayNameFormat) {
      const template = displayNameFormat.formatString
      return Formatter.formatWithTwig(template, this.content)
    }
  }

  public get entityType() {
    const schemas = this.schemas
    const filteredSchemas = _.filter(schemas, (schema) => {
      return schema?.uniqueId !== 'a85e9f04-3baa-4149-8f2f-fbbd8f8d1833'
    })
    return _.get(_.last(filteredSchemas), 'title', 'Other')
  }

  public get entityTypeId() {
    if (this.isSchema) {
      const schemas = this.schemas
      return _.get(_.last(schemas), 'uniqueId')
    } else {
      const mixins = this.activeMixins
      const mixinIds = _.map(mixins, 'entityId')
      return _.last(mixinIds)
    }
  }

  public get isBusy() {
    return this.get('status.state') === Entity.Status.Busy
  }

  public get isDeleted() {
    return this.get('status.state') === Entity.Status.Deleted
  }

  public get isIdle() {
    return this.get('status.state') === Entity.Status.Idle
  }

  public get isNew() {
    return this.get('status.state') === Entity.Status.New
  }

  public get isPending() {
    return this.get('status.state') === Entity.Status.Pending
  }

  /**
   * Determines if the current Entity is older than other.
   * @param other An object corresponding to another Entity to compare to
   * @returns if the other is more current than `this`
   * See (@link Store.materializeContent)
   */
  public isOlderThan(other: any): boolean {
    const isThisNew = this.isPending || this.isNew
    const isStatusChanged =
      this.get('modifiedDate') === other.modifiedDate &&
      this.get('status.state') !== _.get(other, 'status.state')
    const isOtherNewer =
      this.get('modifiedDate') !== other.modifiedDate &&
      moment(this.get('modifiedDate')).isBefore(moment(other.modifiedDate))

    return (isThisNew || isStatusChanged || isOtherNewer) && (!this.isDirty || this.isSchema)
  }

  public get namespaces() {
    const schemas = this.schemas
    const namespaces = _.map(schemas, 'metadata.namespace')
    return _.filter(namespaces, (namespace) => !_.isEmpty(namespace))
  }

  public get isEditableFormula() {
    const backwardSchemas = _.reverse(_.slice(this.schemas))
    const matchingSchema = _.find(backwardSchemas, 'metadata.isEditableFormula')
    return _.get(matchingSchema, 'metadata.isEditableFormula')
  }

  public isEditable(settings, platform: PlatformType) {
    return this.isEditableFormula ?
      evaluateExpression({ settings, platform, ...CustomFormulas }, this.isEditableFormula) :
      true
  }

  public get schemas() {
    const mixins = this.activeMixins
    return _.map(mixins, (edge: any) => {
      const record = this.store.getRecord(edge.entityId)
      if (!record) console.warn(`Record for mixin edge ${edge.entityId} not found.`)
      return record
    })
  }

  public get statusMessage() {
    return this.get('status.progress.message')
  }

  public get isSchema(): boolean {
    return this.hasMixin(SchemaIds.ENTITY_SCHEMA)
  }

  public get isAbstract(): boolean {
    return this.get('metadata.isAbstract', false)
  }

  public get isPacket(): boolean {
    return this.entityType === 'Packet'
  }

  public get isStoryboardPlan(): boolean {
    return this.hasMixin(SchemaIds.STORYBOARD_PLAN)
  }

  public get isBatchClassification(): boolean {
    return this.uniqueId === SchemaIds.BATCH_CLASSIFICATION
  }

  public get isStoryboardExecution(): boolean {
    return this.hasMixin(SchemaIds.STORYBOARD_EXECUTION)
  }

  public get isStoryboard(): boolean {
    return this.isStoryboardPlan || this.isStoryboardExecution
  }

  public get isKioskExecution(): boolean {
    return this.hasMixin(SchemaIds.KIOSK_PRINT_EXECUTION)
  }

  public get shouldAutoCreate(): boolean {
    return this.getProperty('metadata.autoCreate')
  }

  public get actions(): any {
    return this.resolveSubschemaByPath('uiSchema.configs.actions')?.schema
  }

  public get dependenciesForSave(): string[] {
    return _.clone(this.dependencies)
  }

  // TODO(Bobby D): This is a getter with a number of recursive merges. If
  // we can fix the schemas earlier, this can become a O(1) operation.
  public get translationTable(): any {
    const schemas = this.schemas
    const translationTable = _.merge({}, this.get(TRANSLATIONS_PATH))
    return _.reduce(schemas, (translationTable, schema) => {
      return (schema?.uniqueId === '11111111-0000-0000-0000-000000000000' || schema?.uniqueId === '11111111-0000-0000-0000-000000000001') ?
        translationTable :
        _.merge(translationTable, schema?.translationTable)
    }, translationTable)
  }

  /****************************************************************************/
  // Override Proxy Methods
  /****************************************************************************/

  public registerAllProperties() {
    super.registerOwnProperties()
  }

  protected registerOwnProperties() {
    // TODO(Peter): I am not sure about registering precomputation here
    this.registerProperty('precomputation')
    // properties we need to proxy JSON schema properties
    this.registerProperty('definitions')
    this.registerProperty('properties')
    this.registerProperty('required')
    this.registerProperty('type')

    const schemas = this.schemas
    if (_.some(schemas, _.isNil)) {
      console.warn('Entity::registerOwnProperties() : has missing schemas, only registering own properties')
      // TODO - would it be better to initialize partial schema set, ignoring missing values?
      super.registerOwnProperties()
    } else if (_.isEmpty(schemas)) {
      super.registerOwnProperties()
    } else {
      // proxy props on entity.json e.g. uniqueId, status etc...
      _.forEach(this.schemas, (schema) => {
        this.registerSchemaProperties(schema)
      })
    }
  }

  /*
   * Get a list of all properties available on the schema, including from its
   * inherited mixins.
   */
  private getAllPropertyNames(schema: any): string[] {
    const properties = new Set<string>()
    const visited = new Set<string>()
    const stack = [schema]
    const append = (mixin): void => {
        const record = this.store.getRecord(mixin[JSONSchemaResolver.REF_KEY])
        if (record && !visited.has(record.uniqueId)) {
          stack.push(record)
        }
    }
    while (stack.length) {
      const schema = stack.pop()
      visited.add(schema.uniqueId)
      _.forEach(schema.allOf, append)
      _.forEach(schema.anyOf, append)
      _.forEach(schema.oneOf, append)
      _.forEach(schema.properties, (value, key) => {
        properties.add(key)
      })
    }
    return Array.from(properties)
  }

  private registerSchemaProperties(schema) {
    // NOTE: schema is not an Entity during bootstrap
    const namespace = _.get(schema, 'metadata.namespace')

    const properties = this.getAllPropertyNames(schema)
    _.forEach(properties, (key) => {
      if (key !== namespace && !this[key]) {
        this.registerProperty(key)
      }
    })

    const EntityTypeClass = getTypeById(schema.uniqueId)
    if (!EntityTypeClass) {
      this.registerProperty(namespace)
      return
    }

    // if already exists, then only re-initialize
    const existingInstance = this.types[schema.uniqueId]
    if (!_.isNil(existingInstance)) {
      existingInstance.reInitializeWithValues({
        data: this.get(namespace),
        entitySchema: schema,
      })
      return
    }

    const entityTypeInstance = new EntityTypeClass({
      api: this.api,
      data: this.get(namespace),
      entity: this,
      entitySchema: schema,
    })
    // if we previously called Object.defineProperty on namespace we need to
    // delete it first or it will throw because wd do not allow setting
    // namespace directly
    delete this[namespace]
    this[namespace] = entityTypeInstance
    this.types[schema.uniqueId] = entityTypeInstance
  }

  /****************************************************************************/
  // Helper Methods
  /****************************************************************************/

  private getProperty(path) {
    const schemas = this.schemas
    for (let i = schemas.length - 1; i >= 0; --i) {
      if (_.isEmpty(schemas[i])) {
        const mixin = this.activeMixins[i] || {} as Edge
        throw new Error(`missing schema ${mixin.displayName} ${mixin.entityId} during validation`)
      }
      const result = schemas[i].get(path)
      if (result) {
        return result
      }
    }
  }

  public runPreSaveActions(props?: ISaveActionProps) {
    const allActions = {
      ...this.preSaveActions.tempHooks,
      ...this.preSaveActions.stickyHooks,
    }
    const saveActions = _.compact(_.values(allActions))

    if (_.isEmpty(saveActions)) {
      return BPromise.resolve()
    }

    return BPromise.all(_.map(saveActions, (task) => task.action(this, props)))
  }

  /**
   * Default values could be nested inside 'preFilledValues' or 'entityOptions' or it could
   * on the root level, so catch them all, both legacy and current
   *
   * Convert values from this
   * {
   *   entityOptions: {
   *     defaultValues: {
   *       11111111-0000-0000-0000-000000000011: {
   *         document: {
   *           name: 'test'
   *         }
   *       }
   *     }
   *   },
   *   preFilledValues: {
   *     11111111-0000-0000-0000-000000000026: {
   *       billOfLading: {
   *         test: 'test'
   *       }
   *     }
   *   }
   * }
   *
   * to this:
   * {
   *   11111111-0000-0000-0000-000000000011: {
   *      document: {
   *        name: 'test'
   *      }
   *    },
   *    11111111-0000-0000-0000-000000000026: {
   *      billOfLading: {
   *        test: 'test'
   *      }
   *    }
   * }
   * @param values
   */
  private normalizeDefaultValues(values: any = {}) {
    const { preFilledValues = {}, entityOptions = {} } = values
    const defaultValues = _.mapValues(entityOptions, 'defaultValues') || {}
    const flatMixinDefaultValues = { ...preFilledValues, ...defaultValues }

    const validValues = _.omit(values, [
      'preFilledValues',
      'entityOptions',
      'uniqueId',
      'mixins',
      'status',
      'createdBy',
    ])

    return { ...validValues, ...flatMixinDefaultValues }
  }

  /****************************************************************************/
  // Entity refresh
  /****************************************************************************/

  public startRefreshPolling(
    timerClass: { new (...args: any[]): Timer },
    intervalMs: number,
    onRefresh?: () => void
  ): Timer {
    if (!intervalMs) return

    this.stopRefreshPolling()

    const handleRefresh = () => {
      this.refreshPromise = this.reload().then(() => {
        onRefresh && onRefresh()

        return BPromise.resolve()
      })
      return this.refreshPromise
    }

    this.refreshTimer = new timerClass(handleRefresh, Math.max(1000, intervalMs), true)
    return this.refreshTimer
  }

  public stopRefreshPolling(): void {
    this.refreshTimer?.dispose()
    this.refreshPromise?.cancel()
  }
}
