import React from 'react'

// Components that needs to be wrapped (We should try to make api consistents
// so we don't need to wrap them)

/* tslint:disable:rule1 max-line-length */
import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Card } from 'browser/components/atomic-elements/atoms/card/card'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Checkbox } from 'browser/components/atomic-elements/atoms/checkbox/checkbox'
import { ContainerQueryView } from 'browser/components/atomic-elements/atoms/container-query-view/container-query-view'
import { CsvLink } from 'browser/components/atomic-elements/atoms/csv-link/csvLink'
import { EntityErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/entity-error-block'
import { EntityFooter } from 'browser/components/atomic-elements/atoms/footer/entity-footer'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { GeoRegionInput } from 'browser/components/atomic-elements/atoms/geo-region-input/geo-region-input'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { If } from 'browser/components/atomic-elements/atoms/if'
import { CodeMirrorInput } from 'browser/components/atomic-elements/atoms/input/code-mirror-input'
import { CurrencyInput } from 'browser/components/atomic-elements/atoms/input/currency-input'
import { DateInput } from 'browser/components/atomic-elements/atoms/input/date-input/date-input'
import { FormulaInput } from 'browser/components/atomic-elements/atoms/input/formula-input'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { QuantityInput } from 'browser/components/atomic-elements/atoms/input/quantity-input'
import { SecretStringInput } from 'browser/components/atomic-elements/atoms/input/secret-string-input'
import { SequenceInput } from 'browser/components/atomic-elements/atoms/input/sequence-input'
import { TimeInput } from 'browser/components/atomic-elements/atoms/input/time-input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { EntityMap } from 'browser/components/atomic-elements/atoms/mapbox/entity-map'
import { LoadMap } from 'browser/components/atomic-elements/atoms/mapbox/load-map'
import { MasterDetail } from 'browser/components/atomic-elements/atoms/master-detail/master-detail'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { Panel } from 'browser/components/atomic-elements/atoms/panel'
import { Radio } from 'browser/components/atomic-elements/atoms/radio/radio'
import { RadioGroup } from 'browser/components/atomic-elements/atoms/radio/radio-group'
import { RadioGroupField } from 'browser/components/atomic-elements/atoms/radio/radio-group-field'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { Select } from 'browser/components/atomic-elements/atoms/select'
import { CarrierSelect } from 'browser/components/atomic-elements/atoms/select/carrier-select/carrier-select'
import { ContactSelect } from 'browser/components/atomic-elements/atoms/select/contact-select/contact-select'
import { DriverSelect } from 'browser/components/atomic-elements/atoms/select/driver-select/driver-select'
import { default as EntitySelect } from 'browser/components/atomic-elements/atoms/select/entity-select'
import { LocationSelect } from 'browser/components/atomic-elements/atoms/select/location-select/location-select'
import { Separator } from 'browser/components/atomic-elements/atoms/separator'
import { Spacer } from 'browser/components/atomic-elements/atoms/spacer/spacer'
import { Table } from 'browser/components/atomic-elements/atoms/table'
import { TableAccountingMappingCell } from 'browser/components/atomic-elements/atoms/table/cells/table-accounting-mapping-cell'
import { TableButtonCell } from 'browser/components/atomic-elements/atoms/table/cells/table-button-cell'
import { TableCargoValueCell } from 'browser/components/atomic-elements/atoms/table/cells/table-cargo-value-cell'
import { TableConditionalStyleCell } from 'browser/components/atomic-elements/atoms/table/cells/table-conditional-style-cell'
import { TableCarrierNameCell } from 'browser/components/atomic-elements/atoms/table/cells/table-carrier-name-cell'
import { TableCopyCell } from 'browser/components/atomic-elements/atoms/table/cells/table-copy-cell'
import { TableEmailStatusCell } from 'browser/components/atomic-elements/atoms/table/cells/table-email-status-cell'
import { TableEnumLabelCell } from 'browser/components/atomic-elements/atoms/table/cells/table-enum-label-cell'
import { TableFilesCell } from 'browser/components/atomic-elements/atoms/table/cells/table-files-cell'
import { TableFlagCell } from 'browser/components/atomic-elements/atoms/table/cells/table-flag-cell'
import { TableGeoRegionCell } from 'browser/components/atomic-elements/atoms/table/cells/table-geo-region-cell'
import { TableHasRequiredDocsCell } from 'browser/components/atomic-elements/atoms/table/cells/table-has-required-docs-cell'
import { TableIdentifierCell } from 'browser/components/atomic-elements/atoms/table/cells/table-identifier-cell'
import { TableImagingHotkeyCell } from 'browser/components/atomic-elements/atoms/table/cells/table-imaging-hotkey-cell'
import { TableLinkCell } from 'browser/components/atomic-elements/atoms/table/cells/table-link-cell'
import { TableSimpleArrayCell } from 'browser/components/atomic-elements/atoms/table/cells/table-simple-array-cell'
import { TableNumberCell } from 'browser/components/atomic-elements/atoms/table/cells/table-number-cell'
import { TableOrderProgressCell } from 'browser/components/atomic-elements/atoms/table/cells/table-order-progress-cell'
import { TableRequirementStatusCell } from 'browser/components/atomic-elements/atoms/table/cells/table-requirements-cell'
import { TableRbPreferenceRequirementStatusCell } from 'browser/components/atomic-elements/atoms/table/cells/table-rb-preference-requirements-cell'
import { TableWorkflowPlanActiveCell } from 'browser/components/atomic-elements/atoms/table/cells/table-workflow-plan-active-cell'
import { TableSelectCell } from 'browser/components/atomic-elements/atoms/table/cells/table-select-cell'
import { Tabs } from 'browser/components/atomic-elements/atoms/tabs/tabs'
import { Textarea } from 'browser/components/atomic-elements/atoms/textarea/textarea'
import { default as DocumentTypeSelectField } from 'browser/components/atomic-elements/domains/dms/document-type-select-field'
import { HavasExpenseReport } from 'browser/components/atomic-elements/domains/dms/havas-expense-report/havas-expense-report'
import { RoleSelectField } from 'browser/components/atomic-elements/domains/dms/role-select-field'
import { default as BillOfLading } from 'browser/components/atomic-elements/domains/trucking/bill-of-lading/bill-of-lading'
import { default as BlindBillOfLading } from 'browser/components/atomic-elements/domains/trucking/blind-bill-of-lading/blind-bill-of-lading'
import { CapacityAssignmentSheet } from 'browser/components/atomic-elements/domains/trucking/capacity-assignment-sheet/capacity-assignment-sheet'
import { CarrierQuoteAssignmentSheet } from 'browser/components/atomic-elements/domains/trucking/carrier-quote-assignment/carrier-quote-assignment-sheet'
import { default as CarrierRateConfirmation } from 'browser/components/atomic-elements/domains/trucking/carrier-rate-confirmation/carrier-rate-confirmation'
import { default as CustomerInvoice } from 'browser/components/atomic-elements/domains/trucking/customer-invoice/customer-invoice'
import { default as CustomerRateConfirmation } from 'browser/components/atomic-elements/domains/trucking/customer-rate-confirmation/customer-rate-confirmation'
import { default as DriverDispatchInstructions } from 'browser/components/atomic-elements/domains/trucking/driver-dispatch-instructions/driver-dispatch-instructions'
import { default as OrderAccountingExport } from 'browser/components/atomic-elements/domains/trucking/order-accounting-export/order-accounting-export'
import { default as OrderAccountingForm } from 'browser/components/atomic-elements/domains/trucking/order-accounting-form/order-accounting-form'
import { OrderAccountingItemRow } from 'browser/components/atomic-elements/domains/trucking/order-accounting-item-row/order-accounting-item-row'
import { OrderCapacityMatchingTable } from 'browser/components/atomic-elements/domains/trucking/order-capacity-matching-table/order-capacity-matching-table'
import { OrderCargoDeliveryTaskRow } from 'browser/components/atomic-elements/domains/trucking/order-cargo-delivery-task-row/order-cargo-delivery-task-row'
import { ShiffletBrosOrderCargoDeliveryTaskRow } from 'browser/components/atomic-elements/domains/trucking/order-cargo-delivery-task-row/shifflet-bros-order-cargo-delivery-task-row'
import { OrderLabeledInputField } from 'browser/components/atomic-elements/domains/trucking/order-labeled-input-field/order-labeled-input-field'
import { OrderRevenueExpenseTable } from 'browser/components/atomic-elements/domains/trucking/order-revenue-expense-table/order-revenue-expense-table'
import { OrderStop } from 'browser/components/atomic-elements/domains/trucking/order-stop/order-stop'
import { OrderTrackingMap } from 'browser/components/atomic-elements/domains/trucking/order-tracking-map/order-tracking-map'
import { SalesOrderCargoDeliveryTaskRow } from 'browser/components/atomic-elements/domains/trucking/cargo-delivery-task-row/sales-order-cargo-delivery-task-row'
import { TripCargoDeliveryTaskRow } from 'browser/components/atomic-elements/domains/trucking/cargo-delivery-task-row/trip-cargo-delivery-task-row'
import { WithSettingsProps } from 'browser/components/atomic-elements/higher-order-components/with-settings-props'
import { DashboardTile } from 'browser/components/atomic-elements/molecules/dashboard-tile/dashboard-tile'
import { DimensionsField } from 'browser/components/atomic-elements/molecules/dimensions-field/dimensions-field'
import { AddressField } from 'browser/components/atomic-elements/molecules/fields/address-field'
import { CheckboxField } from 'browser/components/atomic-elements/molecules/fields/checkbox-field'
import { CodeMirrorField } from 'browser/components/atomic-elements/molecules/fields/code-mirror-field'
import { CurrencyField } from 'browser/components/atomic-elements/molecules/fields/currency-field'
import { DateField } from 'browser/components/atomic-elements/molecules/fields/date-field'
import { DateRangeField } from 'browser/components/atomic-elements/molecules/fields/date-range-field'
import { DocumentsField } from 'browser/components/atomic-elements/molecules/fields/documents-field'
import { EmailsField } from 'browser/components/atomic-elements/molecules/fields/emails-field'
import { EmbeddedLocationField } from 'browser/components/atomic-elements/molecules/fields/embedded-location-field/embedded-location-field'
import { EmbeddedLocationFieldSheet } from 'browser/components/atomic-elements/molecules/fields/embedded-location-field/embedded-location-field-sheet'
import { FormulaField } from 'browser/components/atomic-elements/molecules/fields/formula-field'
import { GeoRegionField } from 'browser/components/atomic-elements/molecules/fields/geo-region-field/geo-region-field'
import { GeolocationField } from 'browser/components/atomic-elements/molecules/fields/geolocation-field'
import { GPSField } from 'browser/components/atomic-elements/molecules/fields/gps-field'
import { HoursOfOperationField } from 'browser/components/atomic-elements/molecules/fields/hours-of-operation-field/hours-of-operation-field'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { QRCodeField } from 'browser/components/atomic-elements/molecules/fields/qrcode-field'
import { PhoneNumberField } from 'browser/components/atomic-elements/molecules/fields/phone-number-field'
import { QuantityField } from 'browser/components/atomic-elements/molecules/fields/quantity-field'
import { RecipientsField } from 'browser/components/atomic-elements/molecules/fields/recipients-field'
import { SecretStringInputField } from 'browser/components/atomic-elements/molecules/fields/secret-string-field'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { CarrierSelectField } from 'browser/components/atomic-elements/molecules/fields/select-field/carrier-select-field'
import { ContactSelectField } from 'browser/components/atomic-elements/molecules/fields/select-field/contact-select-field'
import { DriverSelectField } from 'browser/components/atomic-elements/molecules/fields/select-field/driver-select-field'
import {
  EntitySelectField,
  EntityAssociationSelectField,
} from 'browser/components/atomic-elements/molecules/fields/select-field/entity-select-field'
import { LocationSelectField } from 'browser/components/atomic-elements/molecules/fields/select-field/location-select-field'
import { SignatureField } from 'browser/components/atomic-elements/molecules/fields/signature-field/signature-field'
import { ESignatureField } from 'browser/components/atomic-elements/molecules/fields/esignature-field/esignature-field'
import { TextareaField } from 'browser/components/atomic-elements/molecules/fields/textarea-field'
import { TimeField } from 'browser/components/atomic-elements/molecules/fields/time-field'
import { ToggleField } from 'browser/components/atomic-elements/molecules/fields/toggle-field'
import { FilesViewer } from 'browser/components/atomic-elements/molecules/files-viewer/files-viewer'
import { MixinCheckboxField } from 'browser/components/atomic-elements/molecules/mixin-checkbox/mixin-checkbox-field'
import { ScrollspyTabs } from 'browser/components/atomic-elements/molecules/scrollspy-tabs'
import { TemperatureRangeField } from 'browser/components/atomic-elements/molecules/temperature-range-field/temperature-range-field'
import { CarrierAnalyticsSection } from 'browser/components/atomic-elements/organisms/analytics/carrier-analytics-section'
import { CustomerAnalyticsSection } from 'browser/components/atomic-elements/organisms/analytics/customer-analytics-section'
import { default as OrderAnalyticsSection } from 'browser/components/atomic-elements/organisms/analytics/order-analytics-section'
import { AppContainer } from 'browser/components/atomic-elements/organisms/app-container/app-container'
import { ChatBar } from 'browser/components/atomic-elements/organisms/chat-bar/chat-bar'
import { default as CommissionReport } from 'browser/components/atomic-elements/organisms/commission-report/commission-report'
import { CreateEmailTriggerModal } from 'browser/components/atomic-elements/organisms/create-email-trigger-modal/create-email-trigger-modal'
import { Dashboard } from 'browser/components/atomic-elements/organisms/dashboard/dashboard'
import { ImagingDetailPanel } from 'browser/components/atomic-elements/organisms/entity/imaging-detail-panel/imaging-detail-panel'
import { default as ImagingDetailCard } from 'browser/components/atomic-elements/organisms/entity/imaging-detail-card/imaging-detail-card'
import { DocumentImagingTemplateEditor } from 'browser/components/atomic-elements/organisms/document-imaging-template-editor/document-imaging-template-editor'
import { DocumentCreationFlow } from 'browser/components/atomic-elements/organisms/entity/document-creation-flow'
import { DocumentTable } from 'browser/components/atomic-elements/organisms/entity/document-table'
import { default as DocumentsList } from 'browser/components/atomic-elements/organisms/entity/documents-list/documents-list'
import { RbDocumentsList } from 'browser/components/atomic-elements/organisms/entity/documents-list/rb-documents-list'
import { Rb2DocumentsList } from 'browser/components/atomic-elements/organisms/entity/documents-list/rb2-documents-list'
import { EntityAssociationList } from 'browser/components/atomic-elements/organisms/entity/entity-associations/entity-associations-list'
import { default as EntityDetailCard } from 'browser/components/atomic-elements/organisms/entity/entity-detail-card/entity-detail-card'
import { EntityHeader } from 'browser/components/atomic-elements/organisms/entity/entity-header/entity-header'
import { EntityMasterDetailLayout } from 'browser/components/atomic-elements/organisms/entity/entity-master-detail-layout'
import { EntityPageContainer } from 'browser/components/atomic-elements/organisms/entity/entity-page-container'
import { EntityTable } from 'browser/components/atomic-elements/organisms/entity/entity-table'
import { ImagingHeader } from 'browser/components/atomic-elements/organisms/entity/imaging-header/imaging-header'
import { InviteTable } from 'browser/components/atomic-elements/organisms/entity/invite-table'
import { default as OrderDetailCard } from 'browser/components/atomic-elements/organisms/entity/order-detail-card/order-detail-card'
import { OrderFooter } from 'browser/components/atomic-elements/organisms/entity/order-detail-card/order-footer'
import { RbDetailCard } from 'browser/components/atomic-elements/organisms/entity/rb-detail-card/rb-detail-card'
import { RbInvoiceTable } from 'browser/components/atomic-elements/organisms/entity/rb-detail-card/rb-invoice-table'
import { RbV2SalesInvoiceTable } from 'browser/components/atomic-elements/organisms/entity/rb-detail-card/rb-v2-sales-invoice-table'
import { RbOrdersTable } from 'browser/components/atomic-elements/organisms/entity/rb-detail-card/rb-orders-table'
import { RbOrdersTable as RbV2OrdersTable } from 'browser/components/atomic-elements/organisms/entity/rb-detail-card/rb-v2-orders-table'
import { RbTable } from 'browser/components/atomic-elements/organisms/entity/rb-table'
import { EntityTableWithGroupSimplification } from 'browser/components/atomic-elements/organisms/entity/entity-table-with-group-simplification'
import { SalesOrderDetailCard } from 'browser/components/atomic-elements/organisms/entity/sales-order-detail-card/sales-order-detail-card'
import { Feed } from 'browser/components/atomic-elements/organisms/feed'
import { FilterCard } from 'browser/components/atomic-elements/organisms/filters/filter-card'
import { ImageEditorCarousel } from 'browser/components/atomic-elements/organisms/image-editor-carousel/image-editor-carousel'
import { Inbox } from 'browser/components/atomic-elements/organisms/inbox/inbox'
import { ApplicationBundleEditor } from 'browser/components/atomic-elements/organisms/internal-tools/application-bundle-editor'
import { EntitySchemaEditor } from 'browser/components/atomic-elements/organisms/internal-tools/entity-schema-editor/entity-schema-editor'
import { JSONEditor } from 'browser/components/atomic-elements/organisms/internal-tools/json-editor'
import { ViewEditor } from 'browser/components/atomic-elements/organisms/internal-tools/view-editor'
import { OmniButton } from 'browser/components/atomic-elements/organisms/omni-button'
import { AddFirmOmniButtonItem } from 'browser/components/atomic-elements/organisms/omni-button/add-firm-omni-button-item'
import { BatchInviteOmniButtonItem } from 'browser/components/atomic-elements/organisms/omni-button/batch-invite-omni-button-item'
import { OmniButtonItem } from 'browser/components/atomic-elements/organisms/omni-button/omni-button-item'
import { OnboardingBlock } from 'browser/components/atomic-elements/organisms/onboarding-block/onboarding-block'
import { OnboardingModal } from 'browser/components/atomic-elements/organisms/onboarding-modal/onboarding-modal'
import { OnboardingPrintOut } from 'browser/components/atomic-elements/organisms/onboarding-print-out/onboarding-print-out'
import { SearchSuggestion } from 'browser/components/atomic-elements/organisms/search/search-suggestion'
import { ShareBar } from 'browser/components/atomic-elements/organisms/share-bar/share-bar'
import { SideNavigationBarItem } from 'browser/components/atomic-elements/organisms/side-navigation-bar-item/side-navigation-bar-item'
import { SideNavigationBar } from 'browser/components/atomic-elements/organisms/side-navigation-bar/side-navigation-bar'
import { SideNavigationBarFolder } from 'browser/components/atomic-elements/organisms/side-navigation-bar-folder/side-navigation-bar-folder'
import { TableActionBar } from 'browser/components/atomic-elements/organisms/table-action-bar/table-action-bar'
import { TestFtpBlock } from 'browser/components/atomic-elements/organisms/test-ftp/test-ftp-block'
import { ToolContainer } from 'browser/components/atomic-elements/organisms/tool-container/tool-container'
import { NotificationsNavbarItem } from 'browser/components/atomic-elements/organisms/top-navigation-bar/notifications-navbar-item'
import { default as SearchBar } from 'browser/components/atomic-elements/organisms/top-navigation-bar/search-bar/search-bar'
import { TopNavigationBar } from 'browser/components/atomic-elements/organisms/top-navigation-bar/top-navigation-bar'
import { UserAccountNavbarItem } from 'browser/components/atomic-elements/organisms/top-navigation-bar/user-account-navbar-item'
import { AnalysisDetailView } from 'browser/components/containers/analysis-detail-view'
import { AnalyticsLaneMap } from 'browser/components/containers/analysis-view/lane-map'
import { DATSettingsView } from 'browser/components/containers/dat-settings-view'
import { DocumentImagingListView } from 'browser/components/containers/document-imaging-list-view'
import { DocumentImagingSettingsView } from 'browser/components/containers/document-imaging-settings-view'
import { EntityDetailView } from 'browser/components/containers/entity-detail-view'
import { EntityListView } from 'browser/components/containers/entity-list-view'
import { GenerateOneUseTokenButton } from 'browser/components/domain/user/generate-one-use-token-button'
import { LinkDriverButton } from 'browser/components/domain/user/link-driver-button'
import { MergeUsersButton } from 'browser/components/domain/user/merge-users-button'
import { PrintEntityButton } from 'browser/components/domain/user/print-entity-button'
import { FirmDetailCard } from 'browser/components/domains/firm/firm-detail-card'
import { UIConditional } from 'browser/components/json-elements/atoms/conditional'
import { FileInput } from 'browser/components/json-elements/atoms/file-input/file-input'
import { Timer } from 'browser/components/atomic-elements/atoms/timer/timer'
import { TimerField } from 'browser/components/atomic-elements/molecules/fields/timer-field/timer-field'

import { UIHtmlTable, UIHtmlTableRow } from 'browser/components/json-elements/atoms/html-table'
import { UIList } from 'browser/components/json-elements/atoms/list'
import { UIRow } from 'browser/components/json-elements/atoms/row'
import { UISplitPane } from 'browser/components/json-elements/atoms/split-pane'
import { UIView } from 'browser/components/json-elements/atoms/view'
import { UIDispatchOrderStopList } from 'browser/components/json-elements/domains/dispatch-order-stop-list'
import { UITripStopList } from 'browser/components/json-elements/domains/trip-stop-list'
import { JSONElementFactory } from 'browser/components/json-elements/higher-order-components/json-element-factory'
import { JSONInputFactory } from 'browser/components/json-elements/higher-order-components/json-input-factory'
import { JSONQuantityFactory } from 'browser/components/json-elements/higher-order-components/json-quantity-factory'
import { JSONSelectFactory } from 'browser/components/json-elements/higher-order-components/json-select-factory'
import { UIFileField } from 'browser/components/json-elements/molecules/file-field'
import { UIImageField } from 'browser/components/json-elements/molecules/image-field'
import { UIListField } from 'browser/components/json-elements/molecules/list-field/list-field'
import { SalesInvoiceDetailSection } from './atomic-elements/organisms/entity/sales-invoice-detail-section/sales-invoice-detail-section'
import { RbV2SalesInvoiceChildTable } from './atomic-elements/organisms/entity/rb-detail-card/rb-v2-sales-invoice-child-table'

import { WorkflowManager } from 'browser/components/containers/workflow-manager'
import { EmbeddedFrame } from 'browser/components/atomic-elements/organisms/embedded-frame/embedded-frame'
import { ContainerView } from './containers/container-view'
import { ImageLoader } from './atomic-elements/atoms/image-loader/image-loader'
import { FollowButton } from './atomic-elements/organisms/follow-button/follow-button'
import { WorkflowButton } from './atomic-elements/organisms/workflow-button/workflow-button'
import { InboxDropdown } from './atomic-elements/organisms/inbox-dropdown/inbox-dropdown'
import { ErrorBlock } from './atomic-elements/atoms/error-block/error-block'
import { UICheckbox, UICheckboxField } from './ui-fields/ui-checkbox'
import { UIInputField } from './ui-fields/ui-input'
import { LabeledToggleField } from './atomic-elements/molecules/fields/toggle-field/labeled-toggle-field'
import { EntityAddButton } from './atomic-elements/organisms/entity/entity-add-button/entity-add-button'
import { SpotsList } from './atomic-elements/organisms/entity/spots/spots-list'
import { PermissionStatusProvider } from 'browser/contexts/permission-status/permission-provider'
import { ScrollDownButtonWrapper } from './atomic-elements/atoms/scroll-down-button-wrapper/scroll-down-button-wrapper'
import { Barcode } from './atomic-elements/atoms/barcode/barcode'
import { BarcodeField } from './atomic-elements/molecules/fields/barcode-field/barcode-field'
import { CarrierSearchField } from './atomic-elements/molecules/fields/select-field/carrier-search-field'
import { CarrierSearch } from './atomic-elements/atoms/select/carrier-search/carrier-search'

import { CurrentLocationWarning } from './atomic-elements/organisms/yms/current-location-warning'
import { RequestedLocationWarning } from './atomic-elements/organisms/yms/requested-location-warning'
import { YardAuditExportButton } from './atomic-elements/organisms/yms/yard-audit-export'
import { TrailerWarning } from './atomic-elements/organisms/yms/trailer-warning'
import { TableEntityPreviewCell } from './atomic-elements/atoms/table/cells/table-entity-preview-cell'
import { CRMSearch } from './atomic-elements/atoms/select/crm-search/crm-search'
import { CRMSearchField } from './atomic-elements/molecules/fields/select-field/crm-search-field'
import { BlindMatchInputField } from './atomic-elements/molecules/fields/blind-match-field/blind-match-field'
import { YardViewer } from './atomic-elements/atoms/mapbox/yard-viewer'

const UICodeMirrorInput = JSONInputFactory(CodeMirrorInput)
const UICurrencyInput = JSONInputFactory(CurrencyInput)
const UIEntitySelect = JSONSelectFactory(EntitySelect)
const UIFormulaInput = JSONInputFactory(FormulaInput)
const UIInput = JSONInputFactory(Input)
const UICarrierSelect = JSONSelectFactory(CarrierSelect)
const UIDriverSelect = JSONSelectFactory(DriverSelect)
const UILocationSelect = JSONSelectFactory(LocationSelect)
const UIContactSelect = JSONSelectFactory(ContactSelect)
const UIQuantityInput = JSONInputFactory(JSONQuantityFactory(QuantityInput))
const UISecretStringInput = JSONInputFactory(SecretStringInput)
const UISequenceInput = JSONSelectFactory(SequenceInput)
const UISelect = JSONSelectFactory(Select)
const UITextarea = JSONElementFactory(Textarea)
const UIAddressField = JSONElementFactory(AddressField)
const UIESignatureField = JSONElementFactory(ESignatureField)
const UICodeMirrorField = JSONInputFactory(CodeMirrorField)
const UICurrencyField = JSONInputFactory(CurrencyField)
const UITimeField = JSONInputFactory(TimeField)
const UIDateField = JSONInputFactory(DateField)
const UITimeInput = JSONInputFactory(TimeInput)
const UIDateInput = JSONInputFactory(DateInput)
const UIDateRangeField = JSONInputFactory(DateRangeField)
const UIEntitySelectField = JSONSelectFactory(EntitySelectField)
const UIEntityAssociationSelectField = JSONSelectFactory(EntityAssociationSelectField)
const UIFormulaField = JSONInputFactory(FormulaField)
const UIEmbeddedLocationField = JSONInputFactory(EmbeddedLocationField)
const UIGeoRegionField = JSONInputFactory(GeoRegionField)
const UIQRCodeField = JSONInputFactory(QRCodeField)
const UISecretStringField = JSONInputFactory(SecretStringInputField)
const UIContactSelectField = JSONSelectFactory(ContactSelectField)
const UICarrierSelectField = JSONSelectFactory(CarrierSelectField)
const UIDriverSelectField = JSONSelectFactory(DriverSelectField)
const UILocationSelectField = JSONSelectFactory(LocationSelectField)
const UIPhoneNumberField = JSONInputFactory(PhoneNumberField)
const UIQuantityField = JSONInputFactory(JSONQuantityFactory(QuantityField))
const UISelectField = JSONSelectFactory(SelectField)
const UITextareaField = JSONElementFactory(TextareaField)
const UIToggle = JSONElementFactory(ToggleField)
const UIToggleField = JSONElementFactory(LabeledToggleField)
const UIGPSField = WithSettingsProps(GPSField, {
  showGPSAsDegree: 'generalSettings.showGpsAsDegrees',
})
const UIGeolocationField = WithSettingsProps(GeolocationField, {
  showGPSAsDegree: 'generalSettings.showGpsAsDegrees',
})
const UIBarcode = JSONElementFactory(Barcode)
const UIBarcodeField = JSONElementFactory(BarcodeField)

const UICarrierSearch = JSONElementFactory(CarrierSearch)
const UICarrierSearchField = JSONElementFactory(CarrierSearchField)

const UICRMSearch = JSONSelectFactory(CRMSearch)
const UICRMSearchField = JSONSelectFactory(CRMSearchField)

const UIBlindMatchInputField = JSONElementFactory(BlindMatchInputField)

export default {
  'ui:carrierSearch': UICarrierSearch,
  'ui:carrierSearchField': UICarrierSearchField,
  'ui:carrierSelect': UICarrierSelect,
  'ui:crmSearch': UICRMSearch,
  'ui:crmSearchField': UICRMSearchField,
  'ui:checkbox': UICheckbox,
  'ui:codeMirrorField': UICodeMirrorField,
  'ui:codeMirrorInput': UICodeMirrorInput,
  'ui:conditional': UIConditional,
  'ui:contactSelect': UIContactSelect,
  'ui:currencyInput': UICurrencyInput,
  'ui:driverSelect': UIDriverSelect,
  'ui:entitySelect': UIEntitySelect,
  'ui:formulaInput': UIFormulaInput,
  'ui:htmlTable': UIHtmlTable,
  'ui:htmlTableRow': UIHtmlTableRow,
  'ui:input': UIInput,
  'ui:list': UIList,
  'ui:listField': UIListField,
  'ui:locationSelect': UILocationSelect,
  'ui:masterDetailPanel': MasterDetail,
  'ui:modal': Modal,
  'ui:panel': Panel,
  'ui:quantityInput': UIQuantityInput,
  'ui:row': UIRow,
  'ui:scrollspyTabs': ScrollspyTabs,
  'ui:blindMatchInputField': UIBlindMatchInputField,
  'ui:secretStringInput': UISecretStringInput,
  'ui:section': Section,
  'ui:select': UISelect,
  'ui:separator': Separator,
  'ui:sequenceInput': UISequenceInput,
  'ui:spacer': Spacer,
  'ui:splitPane': UISplitPane,
  'ui:tabs': Tabs,
  'ui:textarea': UITextarea,
  'ui:view': UIView,
  'ui:scrollDownButtonWrapper': ScrollDownButtonWrapper,
  // tslint:disable-next-line:object-literal-sort-keys
  'ui:addressField': UIAddressField,
  'ui:checkboxField': UICheckboxField,
  'ui:eSignatureField': UIESignatureField,
  'ui:currencyField': UICurrencyField,
  'ui:dateField': UIDateField,
  'ui:dateInput': UIDateInput,
  'ui:timer': Timer,
  'ui:timerField': TimerField,
  'ui:timeInput': UITimeInput,
  'ui:timeField': UITimeField,
  'ui:dateRangeField': UIDateRangeField,
  'ui:embeddedLocationField': UIEmbeddedLocationField,
  'ui:embeddedLocationFieldSheet': EmbeddedLocationFieldSheet,
  'ui:entitySelectField': UIEntitySelectField,
  'ui:entityAssociationSelectField': UIEntityAssociationSelectField,
  'ui:formulaField': UIFormulaField,
  'ui:geoRegionField': UIGeoRegionField,
  'ui:contactSelectField': UIContactSelectField,
  'ui:carrierSelectField': UICarrierSelectField,
  'ui:driverSelectField': UIDriverSelectField,
  'ui:locationSelectField': UILocationSelectField,
  'ui:geolocationField': GeolocationField,
  'ui:inputField': UIInputField,
  'ui:locationField': GPSField,
  'ui:quantityField': UIQuantityField,
  'ui:phoneNumberField': UIPhoneNumberField,
  'ui:secretStringField': UISecretStringField,
  'ui:selectField': UISelectField,
  'ui:signatureField': SignatureField,
  'ui:textareaField': UITextareaField,
  'ui:toggle': UIToggle,
  'ui:toggleField': UIToggleField,
  'ui:feed': Feed,
  'ui:documentsList': DocumentsList,
  'ui:dispatchOrderStopList': UIDispatchOrderStopList,
  'ui:tripStopList': UITripStopList,
  'ui:autofillBlock': AutofillBlock,
  'ui:button': Button,
  'ui:label': Label,
  'ui:containerQueryView': ContainerQueryView,
  'ui:entityErrorBlock': EntityErrorBlock,
  'ui:helpBlock': HelpBlock,
  'ui:radio': Radio,
  'ui:radioGroup': RadioGroup,
  'ui:avatar': Avatar,
  'ui:fileInput': FileInput,
  'ui:formTable': FormTable,
  'ui:footer': Footer,
  'ui:entityFooter': EntityFooter,
  'ui:mapbox': EntityMap,
  'ui:table': Table,
  'ui:table:carrierNameCell': TableCarrierNameCell,
  'ui:table:buttonCell': TableButtonCell,
  'ui:table:copyCell': TableCopyCell,
  'ui:table:filesCell': TableFilesCell,
  'ui:table:geoRegionCell': TableGeoRegionCell,
  'ui:table:flagCell': TableFlagCell,
  'ui:table:enumLabel': TableEnumLabelCell,
  'ui:table:linkCell': TableLinkCell,
  'ui:table:simpleArrayCell': TableSimpleArrayCell,
  'ui:table:numberCell': TableNumberCell,
  'ui:table:orderProgressCell': TableOrderProgressCell,
  'ui:table:identifierCell': TableIdentifierCell,
  'ui:table:rbPreferenceRequirementsCell': TableRbPreferenceRequirementStatusCell,
  'ui:table:requirementsCell': TableRequirementStatusCell,
  'ui:table:workflowPlanActiveCell': TableWorkflowPlanActiveCell,
  'ui:table:select': TableSelectCell,
  'ui:table:accountingMappingCell': TableAccountingMappingCell,
  'ui:table:imagingHotkeyCell': TableImagingHotkeyCell,
  'ui:table:tableCargoValueCell': TableCargoValueCell,
  'ui:table:tableConditionalStyleCell': TableConditionalStyleCell,
  'ui:table:tableEmailStatusCell': TableEmailStatusCell,
  'ui:table:hasRequiredDocsCell': TableHasRequiredDocsCell,
  'ui:table:entityPreviewCell': TableEntityPreviewCell,
  'ui:card': Card,
  'ui:cardHeader': CardHeader,
  'ui:cardHeaderItem': CardHeaderItem,
  'ui:if': If,
  'ui:loadMap': LoadMap,
  'ui:yardMap': YardViewer,
  'ui:analyticsLaneMap': AnalyticsLaneMap,
  'ui:dashboardTile': DashboardTile,
  'ui:dimensionsField': DimensionsField,
  'ui:fileField': UIFileField,
  'ui:imageField': UIImageField,
  'ui:radioGroupField': RadioGroupField,
  'ui:filesViewer': FilesViewer,
  'ui:recipientsField': RecipientsField,
  'ui:temperatureRangeField': TemperatureRangeField,
  'ui:hoursOfOperationField': HoursOfOperationField,
  'ui:appContainer': AppContainer,
  'ui:carrierAnalyticsSection': CarrierAnalyticsSection,
  'ui:orderAnalyticsSection': OrderAnalyticsSection,
  'ui:entityAssociationList': EntityAssociationList,
  'ui:customerAnalyticsSection': CustomerAnalyticsSection,
  'ui:documentCreationFlow': DocumentCreationFlow,
  'ui:documentTable': DocumentTable,
  'ui:entityDetailCard': EntityDetailCard,
  'ui:rbDetailCard': RbDetailCard,
  'ui:entityMasterDetailLayout': EntityMasterDetailLayout,
  'ui:entityHeader': EntityHeader,
  'ui:entityPageContainer': EntityPageContainer,
  'ui:entityTable': EntityTable,
  'ui:entityTableWithGroupSimplification': EntityTableWithGroupSimplification,
  'ui:imageEditorCarousel': ImageEditorCarousel,
  'ui:imagingDetailCard': ImagingDetailCard,
  'ui:filterCard': FilterCard,
  'ui:orderDetailCard': OrderDetailCard,
  'ui:orderFooter': OrderFooter,
  'ui:notificationsNavbarItem': NotificationsNavbarItem,
  'ui:geoRegionInput': GeoRegionInput,
  'ui:inboxDropdown': InboxDropdown,
  'ui:omniButton': OmniButton,
  'ui:omniButtonItem': OmniButtonItem,
  'ui:addFirmOmniButtonItem': AddFirmOmniButtonItem,
  'ui:addBatchInviteOmniButtonItem': BatchInviteOmniButtonItem,
  'ui:searchBar': SearchBar,
  'ui:searchSuggestion': SearchSuggestion,
  'ui:shareBar': ShareBar,
  'ui:sharedWithSelect': UIView,
  'ui:sideNavigationBar': SideNavigationBar,
  'ui:sideNavigationBarItem': SideNavigationBarItem,
  'ui:sideNavigationBarFolder': SideNavigationBarFolder,
  'ui:toolContainer': ToolContainer,
  'ui:topNavigationBar': TopNavigationBar,
  'ui:dashboard': Dashboard,
  'ui:inbox': Inbox,
  'ui:userAccountNavbarItem': UserAccountNavbarItem,
  'ui:chatBar': ChatBar,
  'ui:onboardingBlock': OnboardingBlock,
  'ui:onboardingPrintOut': OnboardingPrintOut,
  'ui:capacityAssignmentSheet': CapacityAssignmentSheet,
  'ui:carrierQuoteAssignmentSheet': CarrierQuoteAssignmentSheet,
  'ui:onboardingModal': OnboardingModal,
  'ui:orderLabeledInputField': OrderLabeledInputField,
  'ui:orderRevenueExpenseTable': OrderRevenueExpenseTable,
  'ui:orderStop': OrderStop,
  'ui:orderTrackingMap': OrderTrackingMap,
  'ui:orderAccountingExport': OrderAccountingExport,
  'ui:orderAccountingForm': OrderAccountingForm,
  'ui:orderAccountingItemRow': OrderAccountingItemRow,
  'ui:orderCapacityMatchingTable': OrderCapacityMatchingTable,
  'ui:orderCargoDeliveryTaskRow': OrderCargoDeliveryTaskRow,
  'ui:salesOrderCargoDeliveryTaskRow': SalesOrderCargoDeliveryTaskRow,
  'ui:tripCargoDeliveryTaskRow': TripCargoDeliveryTaskRow,
  'ui:shiffletBrosOrderCargoDeliveryTaskRow': ShiffletBrosOrderCargoDeliveryTaskRow,
  'ui:carrierRateConfirmation': CarrierRateConfirmation,
  'ui:driverDispatchInstructions': DriverDispatchInstructions,
  'ui:documentTypeSelectField': DocumentTypeSelectField,
  'ui:roleSelectField': RoleSelectField,
  'ui:havasExpenseReport': HavasExpenseReport,
  'ui:customerInvoice': CustomerInvoice,
  'ui:customerRateConfirmation': CustomerRateConfirmation,
  'ui:billOfLading': BillOfLading,
  'ui:blindBillOfLading': BlindBillOfLading,
  'ui:documentImagingTemplateEditor': DocumentImagingTemplateEditor,
  'ui:tableActionBar': TableActionBar,
  'ui:commissionReport': CommissionReport,
  'ui:generateOneUseTokenButton': GenerateOneUseTokenButton,
  'ui:mergeUsersButton': MergeUsersButton,
  'ui:createEmailTriggerModal': CreateEmailTriggerModal,
  'ui:firmDetailCard': FirmDetailCard,
  'ui:imagingDetailPanel': ImagingDetailPanel,
  'ui:documentsField': DocumentsField,
  'ui:emailsField': EmailsField,
  'ui:mixinCheckboxField': MixinCheckboxField,
  'ui:inviteTable': InviteTable,
  'ui:csvLink': CsvLink,
  'ui:linkDriverButton': LinkDriverButton,
  'ui:rbOrdersTable': RbOrdersTable,
  'ui:rbV2OrdersTable': RbV2OrdersTable,
  'ui:rbV2SalesInvoiceChildTable': RbV2SalesInvoiceChildTable,
  'ui:rbDocumentsList': RbDocumentsList,
  'ui:rb2DocumentsList': Rb2DocumentsList,
  'ui:rbInvoiceTable': RbInvoiceTable,
  'ui:rbTable': RbTable,
  'ui:imagingHeader': ImagingHeader,
  'ui:salesOrderDetailCard': SalesOrderDetailCard,
  'ui:salesInvoiceDetailSection': SalesInvoiceDetailSection,
  'ui:testftpblock': TestFtpBlock,
  'ui:rbV2SalesInvoiceTable': RbV2SalesInvoiceTable,
  'ui:qrCodeField': UIQRCodeField,
  'ui:barcode': UIBarcode,
  'ui:barcodeField': UIBarcodeField,
  'ui:entityAddButton': EntityAddButton,
  'ui:spotsList': SpotsList,
  'ui:workflowManager': WorkflowManager,
  'ui:workflowButton': WorkflowButton,
  'ui:embeddedFrame': EmbeddedFrame,
  'ui:image': ImageLoader,
  'ui:followButton': FollowButton,
  'ui:printEntityButton': PrintEntityButton,
  'ui:errorBlock': ErrorBlock,

  // Script
  'script:analysisDetailView': AnalysisDetailView,
  'script:entityListView': EntityListView,
  'script:entityDetailView': EntityDetailView,
  'script:userDatSettingsView': DATSettingsView,
  'script:documentImagingListView': DocumentImagingListView,
  'script:documentImagingSettingsView': DocumentImagingSettingsView,
  'script:containerView': ContainerView,
  'ui:jsonEditor': JSONEditor,
  'ui:applicationBundleEditor': ApplicationBundleEditor,
  'ui:entitySchemaEditor': EntitySchemaEditor,
  'ui:viewEditor': ViewEditor,

  'ui:permissionStatusProvider': PermissionStatusProvider,

  // YMS temp warnings
  'ui:ymsCurrentLocationWarning': CurrentLocationWarning,
  'ui:ymsRequestedLocationWarning': RequestedLocationWarning,
  'ui:ymsTrailerWarning': TrailerWarning,
  'ui:ymsYardAuditExportButton': YardAuditExportButton,
}
